import React from 'react';
import { Link } from 'gatsby';
import Text from 'components/Text';
import Layout from 'components/Layout';
import LayoutContainer from 'components/LayoutContainer';

const NotFoundPage = () => (
  <Layout title="Page not found">
    <LayoutContainer as="main" style={{ marginTop: '150px' }}>
      <Text as="h1" mb={3}>
        Page not found!
      </Text>
      <Text as="h3" mb={4}>
        Sorry, but the page you were looking for could not be found.
      </Text>
      <p>
        You can return to our <Link to="/">home page</Link>.
      </p>
    </LayoutContainer>
  </Layout>
);

export default NotFoundPage;
